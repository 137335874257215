import React, { Component } from "react";
import { Map, TileLayer, Pane, Marker, Popup, Circle, GeoJSON, CircleMarker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import AppUtility from "../AppUtility";

import "./Map.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// const NEARBY_STYLE = {
//   // color: '#b3ce8c',
//   color: '#ff0000',
//   weight: 2,
//   fillOpacity: 0.2
// };

const GEOMETRY_STYLES = {
  field: {
    color: "#b3ce8c",
    // color: '#ffa500',
    weight: 2,
    fillOpacity: 0.4,
  },
  nearbyField: {
    // color: '#3388ff',
    // color: '#ffa500',
    weight: 2,
    fillOpacity: 0.0,
  },
  selectedNearbyField: {
    color: "#66a6ff",
    // color: '#ffa500',
    weight: 2,
    fillOpacity: 0.3,
  },
  selectedField: {
    color: "#4caeb1",
    weight: 2,
    fillOpacity: 0.2,
  },
  accuracyCircle: {
    color: "#3388ff",
    weight: 2,
    fillOpacity: 0.1,
  },
};

class MainMap extends Component {
  leafletMap = null;

  constructor(props) {
    super(props);

    this.leafletMap = React.createRef();

    this.state = {
      center: null,
      geoJsonElements: [],
      selectedGeoJsonElement: null,
      firsttime: true,
      drawAccuracyCircle: false,
    };
  }

  componentDidMount() {
    let leafletElement = this.leafletMap.current.leafletElement;

    leafletElement.attributionControl.setPrefix(false);

    let position = this.props.position;
    if (position) {
      setTimeout(() => this.setState({ drawAccuracyCircle: true }), 5000);
      this.flyToPos(position.coords);
    }
  }

  handleFeatureClick = (feature) => () => {
    if (this.props.selectLocation) {
      return;
    }
    this.props.onFeatureClick(feature);
  };
  handleCircleClick = (feature) => () => {
    if (this.props.selectLocation || this.props.actionPaneMode === AppUtility.actionPaneMode.postPhoto) {
      return;
    }
    this.props.onFeatureClick(feature);
  };
  componentDidUpdate = (prevProps) => {
    const { geoJsons, position } = this.props;

    if (!prevProps.position && position) {
      this.flyToPos(position.coords);
    } else if (
      prevProps.actionPaneMode !== AppUtility.actionPaneMode.postPhoto &&
      this.props.actionPaneMode === AppUtility.actionPaneMode.postPhoto
    ) {
      this.flyToPos(position.coords);
    }

    if (prevProps.geoJsons !== geoJsons) {
      let geoJsonElements = geoJsons.map((feature) => {
        if (feature.geometry.type === "Point") {
          if (this.props.actionPaneMode === AppUtility.actionPaneMode.postPhoto) {
            return null;
          }
          const [lng, lat] = feature.geometry.coordinates;
          return (
            <CircleMarker
              key={feature.properties.id}
              center={{ lat, lng }}
              color="#b3ce8c"
              radius={10}
              onClick={this.handleCircleClick(feature)}
            />
          );
        }
        return (
          <GeoJSON
            key={feature.properties.id}
            data={feature}
            style={
              this.props.actionPaneMode === AppUtility.actionPaneMode.postPhoto
                ? GEOMETRY_STYLES.nearbyField
                : GEOMETRY_STYLES.field
            }
            onEachFeature={(feature, layer) => layer.on({ click: this.handleFeatureClick(feature) })}
          />
        );
      });

      this.setState({ geoJsonElements: geoJsonElements });
    }

    let selectedFeature = this.props.selectedFeature;

    if (prevProps.selectedFeature !== selectedFeature) {
      let selectedGeoJsonElement = null;

      if (selectedFeature) {
        selectedGeoJsonElement = (
          <GeoJSON
            key={selectedFeature.properties.id + "-selected"}
            data={selectedFeature}
            style={
              this.props.actionPaneMode === AppUtility.actionPaneMode.postPhoto
                ? GEOMETRY_STYLES.selectedNearbyField
                : GEOMETRY_STYLES.selectedField
            }
          />
        );
      }

      this.setState({ selectedGeoJsonElement: selectedGeoJsonElement });
    }
  };

  flyToPos = (pos, zoom = 16) => {
    let leafletElement = this.leafletMap.current.leafletElement;

    leafletElement.flyTo(pos, zoom, { animate: true });
  };

  onLeafletViewportChange = () => {
    const center = this.leafletMap.current.leafletElement.getCenter(); //get map center
    this.setState({ center });
  };
  onLeafletViewportChanged = () => {
    if (this.setNewViewportTimer) {
      clearTimeout(this.setNewViewportTimer);
    }

    let cb = () => {
      const center = this.leafletMap.current.leafletElement.getCenter(); //get map center
      const screenBounds = this.leafletMap.current.leafletElement.getBounds();
      const bounds = {
        xMin: screenBounds.getWest(),
        xMax: screenBounds.getEast(),
        yMin: screenBounds.getSouth(),
        yMax: screenBounds.getNorth(),
      };

      if (this.state.firsttime) {
        this.setState({ firsttime: false }, () => {
          this.props.onMapBoundsChange(bounds, center, true);
        });
      } else {
        this.props.onMapBoundsChange(bounds, center, false);
      }
    };

    this.setNewViewportTimer = setTimeout(cb, 400);
  };

  render() {
    const mapCenter = [52.09, 5.1]; // Utrecht

    const fullStyle = { height: "100%", width: "100%" };

    const { position } = this.props;
    let positionMarker = null;
    let accuracyCircle = null;
    if (position) {
      positionMarker = (
        <Marker position={position.coords} opacity={this.props.selectLocation ? 0.6 : 1}>
          <Popup>U bent hier</Popup>
        </Marker>
      );

      accuracyCircle = (
        <Circle
          key={"accuracy" + JSON.stringify(this.props.position)}
          center={position.coords}
          radius={position.accuracy}
          {...GEOMETRY_STYLES.accuracyCircle}
          interactive={false}
        />
      );
    }

    return (
      <div style={fullStyle}>
        <Map
          ref={this.leafletMap}
          center={mapCenter}
          zoom={7}
          zoomSnap={0.5}
          zoomDelta={0.5}
          style={fullStyle}
          zoomControl={false}
          onViewportChanged={this.onLeafletViewportChanged}
          onViewportChange={this.onLeafletViewportChange}
        >
          <Pane style={{ zIndex: 100 }}>
            <TileLayer
              key="base-layer"
              url="https://www.google.com/maps/vt?lyrs=y@189&x={x}&y={y}&z={z}"
              maxNativeZoom={21}
              maxZoom={40}
              noWrap
            />
          </Pane>
          <Pane style={{ zIndex: 150 }}>
            {this.state.geoJsonElements}
            {this.state.selectedGeoJsonElement}
          </Pane>
          <Pane style={{ zIndex: 200 }}>
            {true ? accuracyCircle : null}
            {positionMarker}
            {this.props.selectLocation && this.state.center && (
              <Marker position={this.state.center} draggable>
                <Popup>New plot position</Popup>
              </Marker>
            )}
          </Pane>
        </Map>
      </div>
    );
  }
}

export default MainMap;
