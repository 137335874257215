import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './Navbar.css'

class NavBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	componentDidMount = () => {
	}

	render() {
		return (
			<AppBar className='navbar' position='static'>
				<Toolbar>
					<span className='nav-title'>
						<img src='/Logo_wide_small.png' alt='Biodiversiteits Monitor'/>
					</span>
					<Button color='inherit' onClick={() => this.props.onOpenAccount(true)}>
						{this.props.user ? this.props.user.username : 'Login'}
					</Button>
				</Toolbar>
			</AppBar>
		);
	}
}

export default NavBar;
