import React, { Component } from "react";
import moment from "moment";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";

import ApiManager from "../../ApiManager";

import "./Timeline.css";

const getMessageData = (message) => {
  try {
    return JSON.parse(message.text);
  } catch {
    return {};
  }
};
const combineMessages = (messages) => {
  const map = {};
  const result = [];
  messages.forEach((message) => {
    const data = getMessageData(message);
    if (data.session) {
      if (!map[data.session]) {
        map[data.session] = [];
      }
      map[data.session].push(message);
    } else {
      result.push(message);
    }
  });
  Object.keys(map).forEach((session) => {
    const items = map[session];
    const descriptionItem = items.find((item) => !item.image);
    const group = { ...descriptionItem, group: true };
    group.images = items.map((item) => item).filter((item) => !!item.image);
    group.textMessages = items.map((item) => item).filter((item) => !item.image);
    result.push(group);
  });
  return result.sort((a, b) => {
    const aDate = moment(a.date);
    const bDate = moment(b.date);

    if (aDate.isBefore(bDate)) {
      return 1;
    }
    if (aDate.isAfter(bDate)) {
      return -1;
    }
    return 0;
  });
};

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      geoMessages: null,
    };
  }

  componentDidMount() {
    console.log('MOUNTING timeline')
    console.log(this.props)
    this.getGeoMessages();
  }

  getMessages = async (mapId, layerId, elementId) => {
    let body = {
      mapId,
      layerId,
      geometryIds:[elementId],
    };

    const { result } = await ApiManager.post("/message/get", body, this.props.user);
    return result;
  };
  getGeoMessages = async () => {
    const { selectedFeature } = this.props;
    console.log('getting messages for')
    console.log(selectedFeature)
    let geoMessages
    try {
      geoMessages = await this.getMessages(selectedFeature.properties.mapId, selectedFeature.properties.layerId, selectedFeature.properties.id);
      console.log('found messages')
      console.log(geoMessages)
    } catch (err) {
      alert("Er is een fout opgetreden. Herlaadt de pagina en probeer nog eens.");
      this.setState({ geoMessages: [] });
    }
    let combined = combineMessages(geoMessages)
    console.log(combined)
    this.setState({ geoMessages: combined });


  };

  viewImage = (geoMessage) => {
    if (geoMessage.fullImage) {
      this.props.onModalChange(<img src={geoMessage.fullImage} className="timeline-full-image" />);
      return;
    }

    let body = {
      mapId: this.props.selectedFeature.properties.mapId,
      layerId: this.props.selectedFeature.properties.layerId,
      messageId: geoMessage.id,
    };
    ApiManager.post("/message/image", body, this.props.user)
      .then((fullImage) => {
        let reader = new FileReader();
        reader.readAsDataURL(fullImage);
        reader.onloadend = () => {
          geoMessage.fullImage = reader.result;
          this.viewImage(geoMessage);
        };
      })
      .catch((err) => {
        alert("Er is een fout opgetreden. Probeer nog eens.");
      });
  };

  deleteMessage = async (geoMessage) => {
    const body = {
      mapId: this.props.selectedFeature.properties.mapId,
      layerId: this.props.selectedFeature.properties.layerId,
      messageId: geoMessage.id,
    };
    return ApiManager.post("/message/delete", body, this.props.user);
  };
  onDelete = (geoMessage) => {
    const user = geoMessage.user || geoMessage.images[0].user;
    let confirm = window.confirm(`Weet u zeker dat u deze foto van "${user.username}" wilt deleten?`);

    if (!confirm) {
      return;
    }

    if (geoMessage.group) {
      const promises = geoMessage.images.map((image) => this.deleteMessage(image));
      if (geoMessage.id) {
        promises.push(this.deleteMessage(geoMessage));
      }

      Promise.all(promises)
        .then(() => {
          const remainingGeoMessages = this.state.geoMessages.filter((x) => x.id !== geoMessage.id);
          this.setState({ geoMessages: remainingGeoMessages });
        })
        .catch((err) => {
          console.error(err);
          alert("Er is een fout opgetreden. Probeer nog eens.");
        });
      return;
    }
    this.deleteMessage(geoMessage)
      .then(() => {
        const remainingGeoMessages = this.state.geoMessages.filter((x) => x.id !== geoMessage.id);
        this.setState({ geoMessages: remainingGeoMessages });
      })
      .catch((err) => {
        alert("Er is een fout opgetreden. Probeer nog eens.");
      });
  };

  render() {
    const { user, map } = this.props;

    let timelineItems = null;
    if (this.state.geoMessages) {
      timelineItems = this.state.geoMessages.map((geoMessage) => {
        let canDelete = map.accessLevel >= 600 || (user && geoMessage.user && geoMessage.user.username === user.username);

        return (
          <Step key={geoMessage.id} active>
            <StepLabel>
              {
                <div>
                  <div>
                    {geoMessage.user ? geoMessage.user.username : null}
                    {canDelete ? (
                      <IconButton
                        className="timeline-delete-button"
                        variant="outlined"
                        onClick={() => this.onDelete(geoMessage)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </div>
                  <div className="timeline-geomessage-date">{moment(geoMessage.date).format("DD-MM-YYYY HH:mm")}</div>
                </div>
              }
            </StepLabel>
            {geoMessage.group ? (
              <StepContent>
                {geoMessage.textMessages.map((message) => {
                  const data = getMessageData(message);
                  if (data.answerType === 1) {
                    return (
                      <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                        <li>
                          <Typography variant="body2">Class: {data.class}</Typography>
                        </li>
                      </ul>
                    );
                  }
                  return (
                    <ul key={message.id} style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                      <li>
                        <Typography variant="body2">1: {data[1]}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">2: {data[2]}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">3: {data[3]}</Typography>
                      </li>
                    </ul>
                  );
                })}
                <div style={{ display: "flex", margin: -3, flexWrap: "wrap", width: "90%" }}>
                  {geoMessage.images.map((image) => (
                    <div key={image.id} style={{ padding: 3 }}>
                      <img src={image.thumbnail} onClick={() => this.viewImage(image)} />
                    </div>
                  ))}
                </div>
              </StepContent>
            ) : (
              <StepContent>
                <Typography>{geoMessage.message}</Typography>
                {geoMessage.image ? (
                  <img src={geoMessage.thumbnail} onClick={() => this.viewImage(geoMessage)} />
                ) : null}
              </StepContent>
            )}
          </Step>
        );
      });
    }

    return (
      <div>
        <Stepper orientation="vertical">{timelineItems}</Stepper>
      </div>
    );
  }
}

export default Timeline;
