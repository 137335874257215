const AppUtility = {
  actionPaneMode: {
    none: 0,
    addPhoto: 1,
    postPhoto: 2,
    timeline: 3,
  },
};

export default AppUtility;
