const apiUrl = "https://api.ellipsis-drive.com/v1";
// const apiUrl = "https://dev.api.ellipsis-drive.com/v1";

// const apiUrl = 'http://localhost:7553/v1';

const ApiManager = {
  adminUserName: "admin",

  apiUrl: apiUrl,

  accessLevels: {
    viewMap: 100,
    aggregatedData: 200,
    viewGeoMessages: 300,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    submitRasterData: 515,
    addRestrictedPolygons: 525,
    viewPrivateGeoMessages: 550,
    deleteGeomessages: 600,
    alterOrDeleteCustomPolygons: 700,
    forms: 750,
    customPolygonLayers: 800,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 500, // addPolygons

    min: 0,
    max: 1000,
  },

  get: (url, body, user) => {
    return apiManagerFetch("GET", url, body, user);
  },

  post: (url, body, user) => {
    return apiManagerFetch("POST", url, body, user);
  },

  fetch: (method, url, body, user) => {
    return apiManagerFetch(method, url, body, user);
  },
};

function CustomError(status, message) {
  var error = Error.call(this, message);

  this.name = "API Error";
  this.message = error.message;
  this.stack = error.stack;
  this.status = status;
}

CustomError.prototype = Object.create(Error.prototype);
CustomError.prototype.constructor = CustomError;

async function apiManagerFetch(method, url, body, user) {
  url = `${apiUrl}${url}`;
  let headers = {};

  if (body) {
    headers["Content-Type"] = "application/json";
  }

  if (user) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  let gottenResponse = null;
  let isText = false;
  let isJson = false;

  let options = {
    method: method,
    headers: headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return await fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 429) {
          alert(
            `U heeft de kaart te veel bevraagd. Probeer over een minuut nog eens. Neem contact op met de eigenaar van de kaart om uw limiet te verhogen.`
          );
        }

        let err = { status: response.status, message: response.message };

        throw err;
      }

      gottenResponse = response;

      let contentType = response.headers.get("Content-Type");

      if (contentType) {
        isText = contentType.includes("text");
        isJson = contentType.includes("application/json");
      } else {
        isText = true;
      }

      if (isJson) {
        return response.json();
      } else if (isText) {
        return response.text();
      } else {
        return response.blob();
      }
    })
    .then((result) => {
      if (gottenResponse.status === 200) {
        return result;
      } else {
        if (!isText) {
          throw new CustomError(gottenResponse.status, result.message);
        } else {
          throw new CustomError(gottenResponse.status, result);
        }
      }
    });
}

export default ApiManager;
