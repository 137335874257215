import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { FormControl, MenuItem, Paper, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const AnswerQuestionsStep2 = ({ onSubmitInfo }) => {
  const [start, setStart] = useState(false);
  const [answer1, setAnswer1] = useState(null);
  const [answer2, setAnswer2] = useState(null);
  const [answer3, setAnswer3] = useState(null);

  const handleGrazingChange = (event) => {
    setAnswer1(event.target.value);
  };
  const handleOverseededChange = (event) => {
    setAnswer2(event.target.value);
  };
  const handleRateChange = (event) => {
    setAnswer3(event.target.value);
  };
  const handleSubmitClick = () => {
    onSubmitInfo({ 1: answer1, 2: answer2, 3: answer3 });
  };
  const handleYesClick = () => {
    setStart(true);
  };
  const handleNoClick = () => {
    onSubmitInfo(null);
  };
  if (!start) {
    return (
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">Bent u bekend met het beheer of gebruik van dit grasland?</Typography>
        <div style={{ display: "flex", justifyContent: "space-evenly", paddingTop: 24 }}>
          <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
            Nee
          </Button>
          <Button variant="contained" color="primary" startIcon={<NavigateNextIcon />} onClick={handleYesClick}>
            Ja
          </Button>
        </div>
      </Paper>
    );
  }
  return (
    <div>
      <Typography variant="body2">Fill:</Typography>

      <Typography variant="body2">Wordt dit perceel begraasd?</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select onChange={handleGrazingChange} label="Identify the Grassland" value={answer1}>
          <MenuItem value={"Weet ik niet"}>Weet ik niet</MenuItem>
          <MenuItem value={"Er loopt nu graasvee"}>
            Er loopt nu graasvee
          </MenuItem>
          <MenuItem value={"Er zijn tekenen van begrazing (mestflatten, urineplekken)"}>
            Er zijn tekenen van begrazing (mestflatten, urineplekken)
          </MenuItem>
          <MenuItem value={"Het is mij bekend dat het perceel soms begraasd wordt"}>
            Het is mij bekend dat het perceel soms begraasd wordt
          </MenuItem>
          <MenuItem value={"Nee"}>
            Nee
          </MenuItem>
        </Select>
      </FormControl>


      <Typography variant="body2">Is dit grasland recentelijk (afgelopen 5 jaar) ingezaaid of doorgezaaid?</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select onChange={handleOverseededChange} label="Identify the Grassland" value={answer2}>
          <MenuItem value={"Weet ik niet"}>Weet ik niet</MenuItem>
          <MenuItem value={"Nee"}>Nee</MenuItem>
          <MenuItem value={"Ja, met productieve grassen en/of klavers"}>
            Ja, met productieve grassen en/of klavers
          </MenuItem>
          <MenuItem value={"Ja, met productieve overige kruiden"}>Ja, met productieve overige kruiden</MenuItem>
          <MenuItem value={"Ja, met biodiverse kruiden"}>Ja, met biodiverse kruiden</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="body2">Hoe productief schat u dit grasland in?</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select onChange={handleRateChange} label="Identify the Grassland" value={answer3}>
          <MenuItem value={"Weet ik niet"}>Weet ik niet</MenuItem>
          <MenuItem value={"hoog"}>hoog</MenuItem>
          <MenuItem value={"middel"}>middel</MenuItem>
          <MenuItem value={"laag"}>laag</MenuItem>
        </Select>
      </FormControl>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 24 }}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<DoneIcon />}
          onClick={handleSubmitClick}
        >
          Klaar met deze stap
        </Button>
        <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
          Sla Over
        </Button>
      </div>
    </div>
  );
};
