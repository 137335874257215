import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Paper } from "@material-ui/core";

import choice1 from "./assets/image1.jpg";
import choice2 from "./assets/image2.jpg";
import choice3 from "./assets/image10.jpg";
import choice4 from "./assets/image3.png";
import choice5 from "./assets/image4.png";
import choice6 from "./assets/image6.png";
import choice7 from "./assets/image7.png";
import choice8 from "./assets/image8.png";

export const AnswerQuestionsStepb = ({ onSubmitInfo }) => {
  const [start, setStart] = useState(true);

  const handleSubmit = (value) => () => {
    onSubmitInfo({ class: value });
  };
  const handleYesClick = () => {
    setStart(true);
  };
  const handleNoClick = () => {
    onSubmitInfo(null);
  };
  if (!start) {
    return (
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">
          Bent u deskundig op het terrein van kruidenrijkdom van graslanden, zodat u het grasland kunt typeren (we
          gebruiken de graslandtypen van de veldgids van Schippers et al., 2012).
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-evenly", paddingTop: 24 }}>
          <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
            Nee
          </Button>
          <Button variant="contained" color="primary" startIcon={<NavigateNextIcon />} onClick={handleYesClick}>
            Ja
          </Button>
        </div>
      </Paper>
    );
  }
  return (
    <div>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">
          Bent u bekend met de indeling van Wim Schippers’ veldgids uit 2012? Zo ja vink dat dan het juiste type aan,
          kies anders nee
        </Typography>
      </Paper>

      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>1. Grassen-mix </b>(Type 1 in Schippers, 2012)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice4} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("1")}>
          Select 1
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>2. Dominant stadium</b> (Type 2 in Schippers, 2012)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice5} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("3")}>
          Select 2
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>3. Gras-kruiden-mix </b>(Type 3 in Schippers, 2012)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice6} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("4")}>
          Select 3
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>4. Bloemrijk grasland </b>(Type 4 in Schippers, 2012)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice7} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("4")}>
          Select 4
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>5. Schraalland </b>(Type 5 in Schippers, 2012)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice8} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("5")}>
          Select 5
        </Button>
      </Paper>

      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>6. Nee ik ben hier niet mee bekend</b>
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice8} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("Nee")}>
          Select 5
        </Button>
      </Paper>

      <div style={{ display: "flex", justifyContent: "center", paddingTop: 24 }}>
        <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
          Sla Over
        </Button>
      </div>
    </div>
  );
};
