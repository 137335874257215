import React, { Component } from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/Button";
import { Paper, Snackbar, Typography } from "@material-ui/core";

import DefaultTheme from "./theme";

import AddIcon from "@material-ui/icons/Add";
import bbox from "geojson-bbox";
import Map from "./Map/Map";
import Navbar from "./Navbar/Navbar";
import ActionPane from "./ActionPane/ActionPane";
import ModalView from "./ModalView/ModalView";

import ApiManager from "./ApiManager";
import AppUtility from "./AppUtility";

import "./App.css";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
const theme = createMuiTheme(DefaultTheme);

const ACCOUNTS_URL = "https://account.ellipsis-drive.com/v2";
// const ACCOUNTS_URL = "https://dev.account.ellipsis-drive.com";
// const ACCOUNTS_URL = 'http://localhost:3001';

const LOCAL_STORE_USER_KEY = "user";

export const PLOT_FREE_AREA_MAP_ID = "cee89344-dfe2-41b1-a9ea-c1f4acdf8bfa";
const PLOT_NETHERLANDS_AREA_MAP_ID = "60b634e1-8dfd-4612-bfe2-b828c81e8dce";
const PERCELEN_LAYER_ID = "e3a7b9bf-dba4-4d4f-9738-954973ab0c86";
const FREE_PERCELEN_LAYER_ID = "e13b1302-2c96-445d-adf7-611c83f985eb";

const GEOMESSAGE_LIMIT = 100;
const NEARBY_FIELDS_METER = 15;
const NEARBY_FIELDS_ACCURACY_MODIFIER = 1;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class App extends Component {
  locationError = false;
  gettingGeometries = false;
  freezeGeometries = false;
  lastGetGeometriesCall = null;

  constructor(props) {
    super(props);

    this.dev = false;
    let accountsUrl = "";

    if (!this.dev) {
      accountsUrl = "https://account.ellipsis-drive.com/";
    } else {
      let url = window.location.href;
      let split = url.split(":");
      accountsUrl = `${split[0]}:${split[1]}:${parseInt(split[2].split("/")[0]) + 1}/`;
    }

    this.state = {
      init: null,

      user: null,

      accountsUrl: accountsUrl,
      openAccount: false,

      actionPaneMode: AppUtility.actionPaneMode.none,

      geoJsons: [],
      floatingMessage: null,

      openAddTooltip: false,
      sessionId: null,
      selectLocation: false,
    };
  }

  componentDidMount() {
    this.retrieveUser(() => {
      this.initMapData(() => {
        window.addEventListener("message", this.onAccountAction, false);

        this.setState({ init: true });
      });
    });

    let locationOpts = { enableHighAccuracy: true };

    navigator.geolocation.getCurrentPosition(this.onLocationChange, this.onLocationChangeErr, locationOpts);
    navigator.geolocation.watchPosition(this.onLocationChange, this.onLocationChangeErr, locationOpts);

    if (this.state.user) {
      this.validateUser();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.user) {
      if (!prevState.user || this.state.user.id !== prevState.user.id) {
        this.validateUser();
      }
    }
  }

  validateUser = () => {
    ApiManager.get("/account/info", null, this.state.user)
      .then(() => {
        console.log("user valid");
      })
      .catch(() => {
        console.log("user not valid");
        this.onLogout();
      });
  };

  initAccount = async () => {
    let initObject = { type: "init", dev: false };
    if (this.state.user) {
      initObject.data = this.state.user;
    }
    let iframe = document.getElementById("account");

    iframe.contentWindow.postMessage(initObject, ACCOUNTS_URL);
  };

  onLocationChange = (pos) => {
    let newPos = {
      coords: [pos.coords.latitude, pos.coords.longitude],
      accuracy: pos.coords.accuracy,
    };

    this.setState({ position: newPos }, () => {
      if (this.state.actionPaneMode === AppUtility.actionPaneMode.postPhoto) {
        this.getGeometriesNearPos();
      }
    });
  };

  onLocationChangeErr = (err) => {
    if (!this.locationError) {
      alert(
        `Er is een probleem opgetreden bij het ophalen van uw locatie. Controleer of u de app toestemming heeft gegeven tot uw locatie. Code: ${
          err.code
        }, ${err[err.code] ? err[err.code] : err.message}`
      );
    }

    console.log(err);

    this.locationError = true;
  };

  retrieveUser = async (cb) => {
    let user = null;
    let userJson = localStorage.getItem(LOCAL_STORE_USER_KEY);

    if (!userJson) {
      if (cb) {
        cb();
      }
      return;
    }

    user = JSON.parse(userJson);

    ApiManager.get("/account/validateLogin", null, user)
      .then(() => {
        if (user.username) {
          user.username = user.username.toLowerCase();
        }

        this.setState({ user: user }, cb);
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem(LOCAL_STORE_USER_KEY);

        this.setState({ user: null }, cb);
      });
  };

  initMapData = async (cb) => {
    const { user } = this.state;

    let promises = [
      ApiManager.post(
        "/account/shapes",
        { access: ["subscribed", "public", "owned"], name: "fotos aan percelen (2021)", pageSize: 20 },
        user
      ),

      ApiManager.post("/metadata", { mapId: PLOT_NETHERLANDS_AREA_MAP_ID }, user),
      ApiManager.post(
        "/account/shapes",
        { access: ["subscribed", "public", "owned"], name: "fotos aan locaties (2021)", pageSize: 20 },
        user
      ),
      ApiManager.post("/metadata", { mapId: PLOT_FREE_AREA_MAP_ID }, user),
    ];

    await Promise.all(promises)
      .then((results) => {
        const [maps, metadata, freeAccessMap, freeAccessMapMetadata] = results;
        let plotsNetherlandsMap = maps.result.find((x) => x.id === PLOT_NETHERLANDS_AREA_MAP_ID);
        let extMap = freeAccessMap.result.find((x) => x.id === PLOT_FREE_AREA_MAP_ID);
        if (!plotsNetherlandsMap || !extMap) {
          let err = {
            status: 500,
            message: "Target map was not found in API result.",
          };
          throw err;
        }

        plotsNetherlandsMap.metadata = metadata;
        extMap.metadata = freeAccessMapMetadata;

        let targetPolygonLayer = plotsNetherlandsMap.metadata.geometryLayers.find((x) => x.id === PERCELEN_LAYER_ID);

        if (!targetPolygonLayer) {
          let err = {
            status: 500,
            message: "Target polygon layer was not found in API result.",
          };
          throw err;
        }

        this.setState({ map: plotsNetherlandsMap, extMap }, cb);
      })
      .catch((err) => {
        console.log(err);
        alert(`Er is een fout opgetreden. Herlaadt de pagina en probeer opnieuw.`);
      });
  };
  getMessages = async (mapId, layerId) => {
    return [];
    const body = {
      mapId: mapId,
      layerId: layerId,
      pageSize: 100,
    };

    const { result } = await ApiManager.post("/message/get", body, this.state.user);
    console.log(result);
    return result;
  };

  getGeometry = async (mapId, layerId, bounds) => {
    let body = {
      mapId,
      layerId: layerId,
      bounds,
    };

    const { result } = await ApiManager.post("/geometry/get", body, this.state.user);
    return result.features.map((item) => {
      item.properties.mapId = mapId;
      item.properties.layerId = layerId;
      return item;
    });
  };
  getFeatures = async (mapId, geometryIds, layerId) => {
    const body = { mapId: mapId, layerId: layerId, geometryIds: geometryIds };

    const { result } = await ApiManager.post("/geometry/ids", body, this.state.user);
    return result.features.map((item) => {
      item.properties.mapId = mapId;
      item.properties.layerId = layerId;
      return item;
    });
  };
  getGeometries = async (bounds, all, cb) => {
    if (this.gettingGeometries) {
      return;
    }

    if (this.freezeGeometries) {
      this.lastGetGeometriesCall = () => this.getGeometries(bounds, all, cb);
      return;
    }

    this.gettingGeometries = true;

    const { map, extMap } = this.state;

    let geometryIds = [];
    let geoJsons = [];
    let floatingMessage = "";

    const geoMessages = await this.getMessages(map.id, PERCELEN_LAYER_ID);
    const extGeoMessages = await this.getMessages(this.state.extMap.id, FREE_PERCELEN_LAYER_ID);

    const allMessages = [...geoMessages, ...extGeoMessages];
    if (allMessages) {
      for (let i = 0; i < allMessages.length; i++) {
        const message = allMessages[i];

        if (!geometryIds.includes(message.geometryId)) {
          geometryIds.push(message.geometryId);
        }
      }
    }

    this.setState({ geoMessages: allMessages });

    if (geometryIds.length) {
      const featureCollection = await this.getFeatures(map.id, geometryIds, PERCELEN_LAYER_ID);
      const featureCollection2 = await this.getFeatures(extMap.id, geometryIds, FREE_PERCELEN_LAYER_ID);

      geoJsons = [...featureCollection, ...featureCollection2];

      floatingMessage = `Getoond: Percelen met meest recente foto's`;
    } else {
      floatingMessage = "Geen berichten gevonden binnen het scherm";
    }

    this.setState(
      {
        geoJsons: geoJsons,
        floatingMessage: floatingMessage,
      },
      cb
    );

    this.gettingGeometries = false;
  };

  getGeometriesNearPos = async () => {
    let position = this.state.position;

    let long = position.coords[1];
    let lat = position.coords[0];

    let maxDeviation = NEARBY_FIELDS_METER + NEARBY_FIELDS_ACCURACY_MODIFIER * position.accuracy;
    if (maxDeviation > 1000) {
      maxDeviation = 1000;
    }
    maxDeviation = 500;
    let longDiff = (360 / (40000000 * Math.cos((2 * Math.PI * lat) / 360))) * maxDeviation;
    let latDiff = (360 / 40000000) * maxDeviation;

    let bounds = {
      xMin: long - longDiff,
      xMax: long + longDiff,
      yMin: lat - latDiff,
      yMax: lat + latDiff,
    };

    let cb = () => {
      let geoJsons = this.state.geoJsons;

      this.setState({ nearbyBounds: bounds });

      if (!this.state.selectedFeature) {
        if (geoJsons.length > 0) {
          this.setState({
            floatingMessage: "Selecteer het perceel waarvoor de foto is",
          });
        } else {
          this.setState({
            floatingMessage: "Geen percelen in de buurt. Ga dichter bij een perceel staan.",
          });
        }
      }
    };

    let body = {
      mapId: PLOT_NETHERLANDS_AREA_MAP_ID,
      layerId: PERCELEN_LAYER_ID,
      bounds: bounds,
      pageSize: 30,
    };

    ApiManager.post("/geometry/bounds", body, this.state.user)
      .then((r) => {
        console.log(r);
        let result = r.result.features.map((item) => {
          item.properties.mapId = PLOT_NETHERLANDS_AREA_MAP_ID;
          item.properties.layerId = PERCELEN_LAYER_ID;
          return item;
        });
        this.setState({ geoJsons: result }, cb);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onAccountAction = (event) => {
    if (event.data.type && event.data.type === "login") {
      this.onLogin(event.data.data);
    } else if (event.data.type && event.data.type === "logout") {
      this.onLogout();
    } else if (event.data.type && event.data.type === "overlayClose") {
      this.setState({ openAccount: false });
    }
  };

  onOpenAccount = (open) => {
    if (this.state.openAccount !== open) {
      this.setState({ openAccount: open });
    }
  };

  onLogin = (user) => {
    localStorage.setItem(LOCAL_STORE_USER_KEY, JSON.stringify(user));

    this.setState({ user: user }, this.initMapData);
  };

  onLogout = () => {
    localStorage.removeItem(LOCAL_STORE_USER_KEY);

    this.setState(
      {
        user: null,
        actionPaneMode: AppUtility.actionPaneMode.none,
        selectedFeature: null,
      },
      this.initMapData
    );
  };

  onMapBoundsChange = (mapBounds, mapCenter, hard = false) => {
    if (this.state.actionPaneMode !== AppUtility.actionPaneMode.postPhoto) {
      this.setState({ mapBounds });
      if (hard) {
        this.getGeometries(mapBounds, false);
      }
    } else {
      this.setState({ mapCenter });
    }
  };

  handleLocationSelect = (selectLocation) => {
    this.setState({ selectLocation });
  };
  onAddPhotoClick = () => {
    let user = this.state.user;

    if (!user) {
      this.onOpenAccount(true);
      return;
    }

    if (!this.state.position) {
      alert("Uw locatie is niet gevonden. Sta toe dat deze app uw locatie kan opvragen en probeer daarna nog eens.");
      return;
    }

    this.setState({ sessionId: moment().format() + "_" + user.username });
    this.onActionPaneModeChange(AppUtility.actionPaneMode.postPhoto);
  };

  onActionPaneModeChange = (mode, selectLocation) => {
    if (this.state.actionPaneMode === mode) {
      return;
    }
    this.setState({ actionPaneMode: mode, selectLocation: false });

    if (mode === AppUtility.actionPaneMode.addPhoto && selectLocation) {
      let lng = this.state.mapCenter.lng;
      let lat = this.state.mapCenter.lat;
      this.submitNewPlot(lng, lat);
      return;
    }
    if (mode === AppUtility.actionPaneMode.postPhoto) {
      this.getGeometriesNearPos();
      return;
    }
    if (mode === AppUtility.actionPaneMode.none) {
      this.setState({
        floatingMessage: null,
        geoJsons: [],
        selectedFeature: null,
      });
      this.getGeometries(this.state.mapBounds);
    }
  };

  submitNewPlot = (lng, lat) => {
    const layer = this.state.extMap.metadata.geometryLayers.find((x) => x.id === FREE_PERCELEN_LAYER_ID);
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      properties: {},
    };
    const body = {
      mapId: this.state.extMap.id,
      layerId: FREE_PERCELEN_LAYER_ID,
      features: [feature],
    };
    ApiManager.post("/geometry/add", body, this.state.user)
      .then((result) => {
        const [addedId] = result;
        feature.properties.id = addedId;
        this.setState({ addedFeature: feature });
      })
      .catch((err) => {
        alert("Er is een fout opgetreden. Probeer nog eens.");
      });
  };

  onShowFloatingMessage = (message) => {
    this.setState({ floatingMessage: message });
  };

  onFeatureClick = (feature) => {
    if (this.state.selectedFeature !== feature) {
      this.setState({ selectedFeature: feature });
    }

    if (this.state.actionPaneMode === AppUtility.actionPaneMode.addPhoto) {
      let box = bbox(feature);

      let lat = (box[0] + box[2]) / 2;
      let lon = (box[1] + box[3]) / 2;
      this.submitNewPlot(lat, lon);
    } else if (this.state.actionPaneMode === AppUtility.actionPaneMode.postPhoto) {
      this.setState({ floatingMessage: null });
    } else if (this.state.actionPaneMode === AppUtility.actionPaneMode.none) {
      this.onActionPaneModeChange(AppUtility.actionPaneMode.timeline);
    }
  };

  onFreezeGeometries = (freeze) => {
    this.freezeGeometries = freeze;
    if (this.lastGetGeometriesCall && !freeze) {
      this.lastGetGeometriesCall();
      this.lastGetGeometriesCall = null;
    }
  };

  onModalChange = (content) => {
    if (this.state.modalContent !== content) {
      this.setState({ modalContent: content });
    }
  };

  render() {
    if (!this.state.init) {
      return null;
    }

    let user = this.state.user;

    if (this.state.openAccount) {
      let initObject = { type: "init" };
      if (this.state.user) {
        initObject.data = this.state.user;
      }
      let iframe = document.getElementById("account");
      if (iframe) {
        iframe.contentWindow.postMessage(initObject, ACCOUNTS_URL);
      }
    }

    let commonProps = {
      user: user,
      map: this.state.map,

      actionPaneMode: this.state.actionPaneMode,
      selectedFeature: this.state.selectedFeature,

      position: this.state.position,
      mapBounds: this.state.mapBounds,
      geoJsons: this.state.geoJsons,
      geoMessages: this.state.geoMessages,

      nearbyBounds: this.state.nearbyBounds,

      onOpenAccount: this.onOpenAccount,
      onActionPaneModeChange: this.onActionPaneModeChange,
      onShowFloatingMessage: this.onShowFloatingMessage,
      onFeatureClick: this.onFeatureClick,
      onFreezeGeometries: this.onFreezeGeometries,
      onModalChange: this.onModalChange,
    };

    let floatingMessage = null;
    if (this.state.floatingMessage) {
      floatingMessage = (
        <div className="floating-message-container">
          <Typography variant="overline" className="floating-message" style={{ lineHeight: 1.5 }}>
            {this.state.floatingMessage}
          </Typography>
        </div>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <Navbar {...commonProps} />
        <div className="content">
          <Map
            {...commonProps}
            selectLocation={this.state.selectLocation}
            onMapBoundsChange={this.onMapBoundsChange}
            extMap={this.state.extMap}
          />
        </div>
        {floatingMessage}
        <IconButton
          variant="contained"
          size={"small"}
          className="add-button"
          style={{ height: "100px", width: "100px" }}
          color="primary"
          onClick={this.onAddPhotoClick}
        >
          <AddIcon size={"small"} />
        </IconButton>
        {this.state.actionPaneMode && (
          <ActionPane
            {...commonProps}
            onOpenSnackbar={() => {
              console.log("opening");
              this.setState({ openSnackbar: true });
            }}
            sessionId={this.state.sessionId}
            onLocationSelect={this.handleLocationSelect}
            mapCenter={this.state.mapCenter}
            addedFeature={this.state.addedFeature}
            extMap={this.state.extMap}
          />
        )}
        <Snackbar
          open={this.state.openSnackbar}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({ openSnackbar: false });
          }}
        >
          <Alert severity="success">Upload gelukt!</Alert>
        </Snackbar>
        <div className="account-overlay" style={{ display: this.state.openAccount ? "block" : "none" }}>
          <iframe src={ACCOUNTS_URL} id="account" title="accounts" onLoad={this.initAccount} />
        </div>
        <ModalView {...commonProps}>{this.state.modalContent}</ModalView>
      </ThemeProvider>
    );
  }
}

export default App;
