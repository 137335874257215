import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Paper } from "@material-ui/core";

import choice1 from "./assets/image1.jpg";
import choice2 from "./assets/image2.jpg";
import choice3 from "./assets/image10.jpg";
import choice4 from "./assets/image3.png";
import choice5 from "./assets/image4.png";
import choice6 from "./assets/image6.png";
import choice7 from "./assets/image7.png";
import choice8 from "./assets/image8.png";

export const AnswerQuestionsStep = ({ onSubmitInfo }) => {
  const [start, setStart] = useState(true);

  const handleSubmit = (value) => () => {
    onSubmitInfo({ class: value });
  };
  const handleYesClick = () => {
    setStart(true);
  };
  const handleNoClick = () => {
    console.log("NO");
    onSubmitInfo(null);
  };
  if (!start) {
    return (
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">
          Bent u deskundig op het terrein van kruidenrijkdom van graslanden, zodat u het grasland kunt typeren (we
          gebruiken de graslandtypen van de veldgids van Schippers et al., 2012).
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-evenly", paddingTop: 24 }}>
          <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
            Nee
          </Button>
          <Button variant="contained" color="primary" startIcon={<NavigateNextIcon />} onClick={handleYesClick}>
            Ja
          </Button>
        </div>
      </Paper>
    );
  }
  return (
    <div>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">
          Bent u in staat om voor dit perceel het volgende onderscheid te maken? Zo ja vink dan aan, kies anders nee
        </Typography>
      </Paper>

      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>0-A. Engels Raaigrasland</b>
        </Typography>
        <Typography variant="body2">
          glanzend groene productieve grassoorten; Type 0 in Schippers et al., 2012
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice1} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("A")}>
          Select 0-A
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>0-B. Productieve voederkruiden</b>
        </Typography>
        <Typography variant="body2">
          Ingezaaide productieve voederkruiden (productieve grassen met o.a. smalle weegbree, cichorei en div. klavers)
        </Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice2} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("B")}>
          Select 0-B
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>0-C. Productief klavergrasland</b>
        </Typography>
        <Typography variant="body2">Productief klavergrasland (productieve grassen met minimaal 10% klaver)</Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice3} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("C")}>
          Select 0-C
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>Geen van de typen 0-A, 0-B of 0-C </b>
          (kies dit alleen als u het onderscheid tussen typen 1-5 niet kent) )
        </Typography>
        <Typography variant="body2">Het grasland voldoet aan geen van de genoemde typen</Typography>

        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice4} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("geen")}>
          Select Geen type
        </Button>
      </Paper>

      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2">
          <b>Nee</b>
        </Typography>
        <Typography variant="body2">Ik ben niet in staat deze typering goed te maken.</Typography>
        <div style={{ display: "flex" }}>{true ? null : <img style={{ width: "100%" }} src={choice7} />}</div>
        <Button variant="contained" color="primary" onClick={handleSubmit("4")}>
          Select Nee
        </Button>
      </Paper>

      <div style={{ display: "flex", justifyContent: "center", paddingTop: 24 }}>
        <Button variant="contained" color="secondary" startIcon={<NavigateNextIcon />} onClick={handleNoClick}>
          Sla Over
        </Button>
      </div>
    </div>
  );
};
