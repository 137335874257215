import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import AddPhoto from "./AddPhoto/AddPhoto";
import PostPhoto from "./PostPhoto/PostPhoto";
import Timeline from "./Timeline/Timeline";

import AppUtility from "../AppUtility";

import "./ActionPane.css";
import { Typography } from "@material-ui/core";

const LetOp = () => {
  return (
    <Paper
      variant="outlined"
      square
      style={{
        padding: 12,
        backgroundColor: "#eee",
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
      }}
    >
      <Typography variant="body2">
        <b>Let op: </b>het is broedseizoen, betreed kruidenrijk grasland percelen niet of zo min mogelijk en alleen met
        toestemming van de eigenaar van de percelen
      </Typography>
    </Paper>
  );
};

class ActionPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoBase64: null,
      type: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actionPaneMode !== this.props.actionPaneMode) {
      this.setState({ prevActionPaneMode: prevProps.actionPaneMode });
    }
  }

  onCloseActionPane = () => {
    this.setState({ photoBase64: null });
    this.props.onActionPaneModeChange(AppUtility.actionPaneMode.none);
    ///
  };

  handleTypeChange = () => {
    this.setState({ type: this.state.type + 1 });
  };

  render() {
    let mode = this.props.actionPaneMode;
    console.log(mode);
    // First step
    if (mode === AppUtility.actionPaneMode.postPhoto) {
      return (
        <Paper className="action-pane action-pane-bottom">
          <div
            className="action-pane-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 0,
              paddingTop: 12,
              paddingLeft: 12,
            }}
          >
            <Typography variant="h6">Select Location</Typography>
            <IconButton onClick={this.onCloseActionPane}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="action-pane-content" style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 12 }}>
            <PostPhoto
              selectedFeature={this.props.selectedFeature}
              onActionPaneModeChange={this.props.onActionPaneModeChange}
              onFeatureClick={this.props.onFeatureClick}
              onLocationSelect={this.props.onLocationSelect}
            />
          </div>
        </Paper>
      );
    }

    // Second step
    if (mode === AppUtility.actionPaneMode.addPhoto) {
      let caption = "Stap 1: Overzichtfotos";
      if (this.state.type === 1) {
        caption = "Stap 2: Detailfotos";
      }
      if (this.state.type === 2) {
        caption = "Stap 3a: Classificatie";
      }
      if (this.state.type === 3) {
        caption = "Stap 3b: Classificatie";
      }
      if (this.state.type === 4) {
        caption = "Stap 4: Productiviteit van grasland";
      }
      return (
        <Paper className="action-pane">
          <div
            className="action-pane-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 0,
              paddingTop: 12,
              paddingLeft: 12,
            }}
          >
            <Typography variant="h6">{caption}</Typography>
            <IconButton onClick={this.onCloseActionPane}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "auto" }}>
            {this.state.type === 1 ? <LetOp /> : null}
            <div style={{ paddingBottom: 12 }}>
              <AddPhoto
                {...this.props}
                sessionId={this.props.sessionId}
                onTypeChange={this.handleTypeChange}
                type={this.state.type}
                onOpenSnackbar={this.props.onOpenSnackbar}
                addedFeature={this.props.addedFeature}
                extMap={this.props.extMap}
              />
            </div>
          </div>
        </Paper>
      );
    }

    // Results on timeline
    return (
      <Paper className="action-pane">
        <div className="action-pane-header">
          <h1>{`Perceel ${this.props.selectedFeature.properties.id}`}</h1>
          <IconButton className="action-pane-close-button" onClick={this.onCloseActionPane}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="action-pane-content">
          <Timeline
            {...this.props}
            map={[this.props.map, this.props.extMap].find(
              (map) => map.id === this.props.selectedFeature.properties.mapId
            )}
          />
        </div>
      </Paper>
    );
  }
}

export default ActionPane;
