import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import AppUtility from "../../AppUtility";

import "./PostPhoto.css";
import { Paper, Typography } from "@material-ui/core";

class PostPhoto extends Component {
  state = { manualSelect: false };

  componentDidUpdate(prevProps) {
    if (this.props.selectedFeature && prevProps.selectedFeature !== this.props.selectedFeature) {
      this.props.onActionPaneModeChange(AppUtility.actionPaneMode.addPhoto);
    }
  }

  handleManualButtonClick = () => {
    this.setState({ manualSelect: true });
    this.props.onLocationSelect(true);
  };
  handleSubmitButtonClick = () => {
    this.props.onActionPaneModeChange(AppUtility.actionPaneMode.addPhoto, this.state.manualSelect);
  };

  render() {
    if (this.state.manualSelect) {
      return (
        <>
          <Paper
            variant="outlined"
            square
            style={{ padding: 12, backgroundColor: "#eee", display: "flex", alignItems: "center" }}
          >
            <ControlCameraIcon style={{ paddingRight: 6, marginTop: -6 }} />
            <Typography variant="body2">Drag map to point</Typography>
          </Paper>

          <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 12 }}>
            <Button variant="contained" color="primary" onClick={this.handleSubmitButtonClick}>
              Select and Proceed
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <Paper
          variant="outlined"
          square
          style={{ padding: 12, backgroundColor: "#eee", display: "flex", alignItems: "center" }}
        >
          <TouchAppIcon style={{ paddingRight: 6, marginTop: -6 }} />
          <Typography variant="body2">Selecteer perceel op kaart</Typography>
        </Paper>

        <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 12 }}>
          <Button variant="contained" color="primary" onClick={this.handleManualButtonClick}>
            Perceel is niet zichtbaar
          </Button>
        </div>
      </>
    );
  }
}

export default PostPhoto;
