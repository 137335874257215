import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import AppUtility from "../../AppUtility";
import MuiAlert from "@material-ui/lab/Alert";

import "./AddPhoto.css";
import { Paper, Snackbar, Typography } from "@material-ui/core";
import ApiManager from "../../ApiManager";
import PublishIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { AnswerQuestionsStep } from "./AnswerQuestionsStep";
import { AnswerQuestionsStep2 } from "./AnswerQuestionsStep2";
import { AnswerQuestionsStepb } from "./AnswerQuestionsStepb";

import example1 from "./assets/image5.png";
import example2 from "./assets/image9.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(
  (theme) => ({
    listItem: {
      display: "flex",
      gap: "6px",
    },
  }),
  { name: "AddPhoto" }
);

const Instructions = ({ type, canProceed, count }) => {
  const styles = useStyles();
  if (type === 0) {
    return (
      <Paper
        variant="outlined"
        square
        style={{
          padding: 12,
          backgroundColor: "#eee",
          display: "flex",
          flexDirection: "column",
          fontSize: 14,
        }}
      >
        <div className={styles.listItem}>
          -
          <Typography variant="body2">
            Vanaf de rand van het perceel, neemt u de foto’s staand en op ooghoogte
          </Typography>
        </div>
        <div className={styles.listItem}>
          -<Typography variant="body2">Neem 2 foto’s en vermijd daarbij overlap</Typography>
        </div>
        <div className={styles.listItem}>
          -<Typography variant="body2">Zorg dat u een klein stukje horizon of lucht op de foto zet</Typography>
        </div>
        <div className={styles.listItem}>
          -
          <Typography variant="body2">
            Vermijd storende/afwijkende objecten op de foto, zoals een hekwerk of koe
          </Typography>
        </div>
        <div className={styles.listItem}>
          -<Typography variant="body2">Vermijd de perceelrand en/of de slootkant</Typography>
        </div>
        <div className={styles.listItem}>
          -<Typography variant="body2">Voorbeeld van een goede overzichtsfoto:</Typography>
        </div>
        <div className={styles.listItem}>
          <img style={{ width: "100%" }} src={example1} />
        </div>
      </Paper>
    );
  }
  return (
    <Paper
      variant="outlined"
      square
      style={{
        padding: 12,
        backgroundColor: "#eee",
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
      }}
    >
      <div style={{ display: "flex" }}>
        -
        <Typography variant="body2">
          Richt de camera meer naar beneden voor het vastleggen van plantensoorten
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        -<Typography variant="body2">Maak representatieve fotos niet alleen de mooiste stukjes</Typography>
      </div>
      <div style={{ display: "flex" }}>
        -<Typography variant="body2">Neem 2 foto’s en vermijd daarbij overlap</Typography>
      </div>
      <div style={{ display: "flex" }}>
        -
        <Typography variant="body2">
          <u>Als u daarvoor toestemming heeft en geen dieren verstoort</u>, kunt u in het perceel gaan staan en meerdere
          detailfoto’s maken op verschillende plekken in het perceel
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        -
        <Typography variant="body2">
          Maak niet alleen foto’s van de allermooiste stukjes, maar juist van representatieve gedeelten
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        -<Typography variant="body2">Vermijd storende objecten zoals uw eigen schoenen</Typography>
      </div>
      <div style={{ display: "flex" }}>
        -<Typography variant="body2">Vermijd overlap tussen de foto’s</Typography>
      </div>
      <div style={{ display: "flex" }}>
        <img style={{ width: "100%" }} src={example2} />
      </div>
    </Paper>
  );
};

const Confirmation = ({ src, handleConfirmClick, handleRejectClick }) => {
  return (
    <div>
      <img src={src} style={{ width: "100%" }} />
      <div style={{ padding: 24, display: "flex", flexDirection: "column", justifyItems: "center" }}>
        <Button variant="contained" color="secondary" startIcon={<CancelIcon />} onClick={handleRejectClick}>
          Opnieuw
        </Button>

        <Button
          style={{ marginTop: 24 }}
          variant="contained"
          color="primary"
          startIcon={<PublishIcon />}
          onClick={handleConfirmClick}
        >
          Gebruik deze foto
        </Button>
      </div>
    </div>
  );
};

const theme = createMuiTheme({ palette: { primary: blue } });

const ProceedButton = ({ visible, handleProceedClick }) => {
  if (!visible) {
    return null;
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary" startIcon={<NavigateNextIcon />} onClick={handleProceedClick}>
          Klaar met deze stap
        </Button>
      </ThemeProvider>
    </div>
  );
};

const UploadButton = ({ step, type, onPhotoChange }) => {
  return (
    <div className="add-photo-select-button-container">
      <input
        key={step}
        accept="image/*"
        style={{ display: "none" }}
        id="photo-upload-input"
        type="file"
        onChange={onPhotoChange}
        capture="camera"
      />
      <label htmlFor="photo-upload-input">
        <Button variant="contained" color="primary" component="span" startIcon={<AddAPhotoIcon />}>
          Maak {type ? "Detailfoto" : "Overzichtfoto"} {step}
        </Button>
      </label>
    </div>
  );
};

class AddPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1, open: false, photos: [[], []] };
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };
  handleSubmit = (message, complete, answerType) => {
    const body = {
      text: JSON.stringify({ ...message, session: this.props.sessionId, answerType }),
      location: {
        x: this.props.position.coords[1],
        y: this.props.position.coords[0],
      },
    };

    body.mapId = this.props.extMap.id;
    body.layerId = "e13b1302-2c96-445d-adf7-611c83f985eb";
    body.geometryId = this.props.addedFeature.properties.id;

    ApiManager.post("/message/add", body, this.props.user)
      .then(() => {
        complete && this.props.onActionPaneModeChange(AppUtility.actionPaneMode.none);
      })
      .catch((err) => {
        alert("Er is een fout opgetreden. Probeer nog eens.");
      });
  };
  handleSubmitInfo1 = (message) => {
    this.props.onTypeChange();
    message && this.handleSubmit(message, false, 1);
  };
  handleSubmitInfo2 = (message) => {
    this.props.onOpenSnackbar();
    if (message) {
      this.handleSubmit(message, true, 2);
    } else {
      this.props.onActionPaneModeChange(AppUtility.actionPaneMode.none);
    }
  };
  handleProceedClick = () => {
    this.setState({ step: 1 });
    this.props.onTypeChange();
  };
  handleRejectClick = () => {
    this.setState({ confirm: null });
  };

  handleConfirmClick = (base64) => {
    const photos = [...this.state.photos];
    photos[this.props.type].push(base64);

    const body = {
      image: base64,
      text: JSON.stringify({
        session: this.props.sessionId,
        photoType: this.props.type ? "Detailfoto" : "Overzichtfoto",
      }),
      location: {
        x: this.props.position.coords[1],
        y: this.props.position.coords[0],
      },
    };

    body.mapId = this.props.extMap.id;
    body.layerId = "e13b1302-2c96-445d-adf7-611c83f985eb";
    body.geometryId = this.props.addedFeature.properties.id;

    ApiManager.post("/message/add", body, this.props.user)
      .then((result) => {
        //        this.setState({ open: true, snackbarOpen: true });
      })
      .catch((err) => {
        alert("Er is een fout opgetreden. Probeer nog eens.");
      });
    this.setState({ step: this.state.step + 1, photos, confirm: null });
  };
  onPhotoChange = (e) => {
    let file = e.target.files[0];

    if (!file.type.includes("image")) {
      alert("Foto moet een plaatje zijn.");
      return;
    }

    toBase64(file)
      .then((base64) => {
        console.log("uploaded");
        console.log(base64);
        this.handleConfirmClick(base64);
        this.setState({ confirm: base64 });
      })
      .catch((err) => {
        alert(`Foutief bestand. Probeer nog eens of een ander bestand.`);
      });
  };

  render() {
    if (this.props.type === 2) {
      return <AnswerQuestionsStep onSubmitInfo={this.handleSubmitInfo1} />;
    }
    if (this.props.type === 3) {
      return <AnswerQuestionsStepb onSubmitInfo={this.handleSubmitInfo1} />;
    }

    if (this.props.type === 4) {
      return <AnswerQuestionsStep2 onSubmitInfo={this.handleSubmitInfo2} />;
    }

    const canProceed = this.state.photos[this.props.type] && this.state.photos[this.props.type].length > 1;
    return (
      <>
        <Instructions
          type={this.props.type}
          canProceed={canProceed}
          count={this.state.photos[this.props.type].length + 1}
        />
        <UploadButton step={this.state.step} type={this.props.type} onPhotoChange={this.onPhotoChange} />
        <ProceedButton type={this.props.type} visible={canProceed} handleProceedClick={this.handleProceedClick} />
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ snackbarOpen: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">Upload gelukt!</Alert>
        </Snackbar>
      </>
    );
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default AddPhoto;
